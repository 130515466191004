import React from 'react'

const CustomInput = (props) => {
  const { type, name, placeholder, classname, value, onChange, onBlur, disabled } = props;
  return (
    <div>
      <input type={type} name={name} className={`form-control ${classname}`} placeholder={placeholder} value={value} onChange={onChange} onBlur={onBlur} disabled={disabled}/>
    </div>
  )
}

export default CustomInput
