import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';

const ShippingPolicy = () => {
  return (
    <>
      <Meta title={'Shipping Policy'} />
      <BreadCrumb title='Shipping Policy' />
      <Container class1='policy-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
              <h1>Delivery and Shipping Policy</h1>
              <div className='d-flex gap-10 flex-column my-3'>
                <p className='product-data'>Shipping and returns available on all orders, costs depend on your delivery address. Special offer for large items and heavy-bulky services. We deliver or ship all our domestic orders within 5-10 business days! Want to check the status of your order? Go to Your Orders to find tracking information and order details. For not received, missed or missed from items, Go to Your Orders to find tracking information and order details or contact us. Please give eligible items and addresses to get delivery and return guarantee.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Global Delivery and Shipping:</h3>
                <p className='product-data'>For global delivery and shipping, please contact us.</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ShippingPolicy
