import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <>
      <Meta title={'Terms and Conditions'} />
      <BreadCrumb title='Terms and Conditions' />
      <Container class1='policy-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
              <h1>Rules</h1>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Please be aware of the rules.</h3>
                <p className='product-data'>Nile provides websites, software, and Services that you can use it. We sell products that you can buy them. By Using Nile products you agree to the following terms. If you use Nile Services, they sometimes contain extra conditions, there are guidelines, terms and agreements applicable.</p>
              </div>
              <div className='d-flex gap-15 flex-column my-0'>
                <h3 className='about-us'>Prices, Item Details:</h3>
                <p className='product-data'><b>Pricing:</b> Prices of products are provided by a manufacturer, supplier, or seller. We cannot confirm the price of a product until you order. We generally do not charge your credit card until after your order has entered the delivery or shipping process or, for digital products, until we make the digital product available to you. <b>Product Descriptions:</b> Nile tries to be descriptive as possible and attempts to inform the product providors to be. There is no warranty that the item detail is accurate, complete, reliable, current, or error-free.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Account, Copyright, Trademarks:</h3>
                <p className='product-data'><b>Account:</b> You need your own account to use Nile sevices and for payments. You are responsible for your activities and account inclusive password. Nile reserves the right to refuse service, terminate accounts, terminate your rights to use Nile Services, remove or edit content, or cancel orders in its sole discretion., <b>Copyright:</b> All content included in or made available through any Nile Service, is the property of Nile or its content providors and protected by Ethiopia and international copyright laws. <b>Trademarks:</b> Logos, service names of Nile are trademarks of Nile in Ethiopia and other countries. Trademarks, that comes on Nile services are property of their owners.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Communications:</h3>
                <p className='product-data'><b>Electronic communications:</b> When you use Nile Services, send email, text, messages, notices and we write you that way back you agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. <b>Reviews, Comments. Other Communications:</b> You may post reviews, comments, photos, videos, and other content; send e-cards and other communications; and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity rights), or otherwise injurious to third parties or objectionable, and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic messages. You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. Nile reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content.</p>
                <p className='product-data'>If you do post content or submit material, and unless we indicate otherwise, you grant Nile a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, perform, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant Nile and sublicensees the right to use the name that you submit in connection with such content, if they choose. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify Nile for all claims resulting from content you supply. Nile has the right but not the obligation to monitor and edit or remove any activity or content. Nile takes no responsibility and assumes no liability for any content posted by you or any third party.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Permissions:</h3>
                <p className='product-data'><b>App Permissions:</b> By using Nile apps you permit to us your devices permissions. <b>Other Businesses:</b> Other products, services or software providors on Nile are linked to their sites or affiliated companies. If you buy any of those products, you are buying directly from them, not from Nile. We are not responsible, reliable for any of that. You should carefully review their privacy statements and other conditions of use.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Policy:</h3>
                <p className='product-data'><b>Privacy:</b> Please read our <Link to='/privacy-policy'>Privacy Notice</Link>. <b>Returns & Refund:</b> Please read our <Link to='/return-policy'>Returns and Refund Policy</Link>. <b>Delivery, Shipping, Export Policy:</b> Please read our <Link to='/shipping-policy'>Delivery and Shipping Policy</Link>. <b>Disputies:</b> Any dispute or claim relating in any way to your use of any Nile Service will be adjudicated in the state or Federal courts in Addis Ababa, Ethiopia, and you consent to exclusive jurisdiction and venue in these courts. We each waive any right to a jury trial. <b>Applicable Law:</b>  By using any Nile Service, you agree that applicable federal law, and the laws of the state of Addis Ababa, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and Nile. <b>Other Policies:</b> We reserve the right to make changes to our site, policies, Service Terms, and these Conditions of Use at any time. Please read our other policies. <b>Rules for Children:</b> Please read our <Link to='/privacy-policy'>Privacy Policy</Link>.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Our Address:</h3>
                <pre className='product-data'>Nile Inc. Tulu Demtu, Addis Ababa Ethiopia</pre>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default TermsAndConditions
