import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import OurStore from './pages/OurStore';
import Blog from './pages/Blog';
import CompareProduct from './pages/CompareProduct';
import Wishlist from './pages/Wishlist';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import SingleBlog from './pages/SingleBlog';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import AdsPolicy from './pages/AdsPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import SingleProduct from './pages/SingleProduct';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import Profile from './pages/Profile';
import {PrivateRoutes} from './routing/PrivateRoutes';
import {OpenRoutes} from './routing/OpenRoutes';
import Invest from './pages/Invest';
import Sell from './pages/Sell';
import Job from './pages/Job';

function App() {
  return (
  <>
  <BrowserRouter>
  <Routes>
    <Route path="login" element={<OpenRoutes><Login/></OpenRoutes>} />
    <Route path="signup" element={<OpenRoutes><Signup/></OpenRoutes>} />
    <Route path='/' element={<Layout/>}>
      <Route index element={<Home/>}/>
      <Route path='about' element={<About/>}/>
      <Route path="contact" element={<Contact />} />
      <Route path="product" element={<OurStore />} />
      <Route path="product/:id" element={<SingleProduct/>} />
      <Route path="blogs" element={<Blog/>} />
      <Route path="blog/:id" element={<SingleBlog/>} />
      <Route path="cart" element={<PrivateRoutes><Cart/></PrivateRoutes>} />
      <Route path="my-orders" element={<PrivateRoutes><Orders/></PrivateRoutes>} />
      <Route path="profile" element={<PrivateRoutes><Profile/></PrivateRoutes>} />
      <Route path="checkout" element={<PrivateRoutes><Checkout/></PrivateRoutes>} />
      <Route path="compare-product" element={<CompareProduct/>} />
      <Route path="wishlist" element={<PrivateRoutes><Wishlist/></PrivateRoutes>} />
      <Route path="privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="shipping-policy" element={<ShippingPolicy/>} />
      <Route path="terms-conditions" element={<TermsAndConditions/>} />
      <Route path="ads-policy" element={<AdsPolicy/>} />
      <Route path="return-policy" element={<ReturnPolicy/>} />
      <Route path='investor' element={<Invest/>}/>
      <Route path='sell' element={<Sell/>}/>
      <Route path='career' element={<Job/>}/>
    </Route>
    <Route path="forgot-password" element={<ForgotPassword/>} />
    <Route path="reset-password/:token" element={<ResetPassword/>} />
  </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
