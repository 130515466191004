import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {BiArrowBack} from 'react-icons/bi';
import Container from '../components/Container';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { config } from '../utils/axiosConfig';
import { createAnOrder, deleteUserCart, resetState } from '../features/user/userSlice';

const shippingSchema = yup.object({
  firstname: yup.string().required('First Name is Required'),
  lastname: yup.string().required('Last Name is Required'),
  address: yup.string().required('Address details are Required'),
  city: yup.string().required('City is Required'),
  state: yup.string().required('State is Required'),
  country: yup.string().required('Country is Required'),
  pincode: yup.number().required('Pincode is Required'),
});

const Checkout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cartState = useSelector(state => state?.auth?.cartProducts)
  const authState = useSelector(state => state.auth)
  const [totalAmount,setTotalAmount] = useState(null);
  const [shippingInfo,setShippingInfo] = useState(null)
  const [cartProductState,setCartProductState] = useState([])

  const getTokenFromLocalStorage = localStorage.getItem('customer')
  ? JSON.parse(localStorage.getItem('customer'))
  : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ''
      }`,
      Accept: 'application/json',
    },
  };

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + (Number(cartState[index].quantity) * cartState[index].price)
      setTotalAmount(sum)
    }
  },[cartState])

  useEffect(() => {
    if (authState?.orderedProduct?.order !== null && authState?.orderedProduct?.success === true) {
      navigate('/my-orders')
    }
  },[authState])
  
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      other: '',
    },
    validationSchema: shippingSchema,
    onSubmit: (values) => {
     setShippingInfo(values)
     localStorage.setItem('address',JSON.stringify(values))
      setTimeout(() =>{
        checkOutHandler()
      }, 300)
    },
  });

  // Payment Gateway & Implement
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    let items=[]
    for (let index = 0; index < cartState?.length; index++) {
      items.push({productId:cartState[index].productId._id,quantity:cartState[index].quantity,color:cartState[index].color._id,price:cartState[index].price})
    }
    setCartProductState(items)
  },[])

  // Gateway for Razorpay India
  const checkOutHandler = async () => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
      alert('Razorpay SDK failed to load')
      return;
    }
    const result = await axios.post('https://nil-5zax.onrender.com/api/user/order/checkout', {amount:totalAmount+200}, config)
    if (!result) {
      alert('Something Went Wrong!')
      return;
    }
    const {amount, id: order_id, currency } = result.data.order
    const options = {
      key: "rzp_test_qM1qxIn32m5zvF",
      amount: amount,
      currency: currency,
      name: "Nile",
      description: "Test Transaction",
      order_id: order_id,
      handler: async function (response) {
        const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
        };

        const result = await axios.post("https://nil-5zax.onrender.com/api/user/order/payment-verification", data, config);

        dispatch(createAnOrder({totalPrice:totalAmount,totalPriceAfterDiscount:totalAmount,orderItems:cartProductState,paymentInfo:result.data,shippingInfo:JSON.parse(localStorage.getItem('address'))}))
        dispatch(deleteUserCart(config2))
        localStorage.removeItem('address')
        dispatch(resetState())
      },
      prefill: {
          name: "Nile",
          email: "elellenile@gmail.com",
          contact: "+251988309638",
      },
      notes: {
          address: "Nile Office",
      },
      theme: {
          color: "#61dafb",
      },
  };


  const paymentObject = new window.Razorpay(options);
  paymentObject.open();

  }


  return (
    <>
      <Container class1='checkout-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-7'>
            <nav style={{ '--bs-breadcrumb-divider': '>' }} aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li class="breadcrumb-item"><Link to='/cart' className='text-dark total-price'>Cart</Link></li>
                &nbsp; /
                <li className="breadcrumb-item total-price active" aria-current="page">Information</li>
                &nbsp; /
                <li class="breadcrumb-item active">Shipping</li>
                &nbsp; /
                <li className="breadcrumb-item active" aria-current="page">Payment</li>
              </ol>
            </nav>
            <h4 className='mb-3'>Delivery Address</h4>
            <form onSubmit={formik.handleSubmit} action='' className='d-flex gap-15 flex-wrap justify-content-between'>
              <div className='w-100'>
                <input type='text' className='form-control' placeholder='Enter Country' name='country' value={formik.values.country} onChange={formik.handleChange('country')} onBlur={formik.handleBlur('country')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.country && formik.errors.country
                  }
                </div>
              </div>
              <div className='flex-grow-1'>
                <input type='text' className='form-control' placeholder='First name' name='firstname' value={formik.values.firstname} onChange={formik.handleChange('firstname')} onBlur={formik.handleBlur('firstname')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.firstname && formik.errors.firstname
                  }
                </div>
              </div>
              <div className='flex-grow-1'>
                <input type='text' className='form-control' placeholder='Last name' name='lastname' value={formik.values.lastname} onChange={formik.handleChange('lastname')} onBlur={formik.handleBlur('lastname')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.lastname && formik.errors.lastname
                  }
                </div>
              </div>
              <div className='w-100'>
                <input type='text' className='form-control' placeholder='Address' name='address' value={formik.values.address} onChange={formik.handleChange('address')} onBlur={formik.handleBlur('address')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.address && formik.errors.address
                  }
                </div>
              </div>
              <div className='w-100'>
                <input type='text' className='form-control' placeholder='Apartment, suite, etc. (optional)' name='other' value={formik.values.other} onChange={formik.handleChange('other')} onBlur={formik.handleBlur('other')}/>
              </div>
              <div className='flex-grow-1'>
                <input type='text' className='form-control' placeholder='City' name='city' value={formik.values.city} onChange={formik.handleChange('city')} onBlur={formik.handleBlur('city')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.city && formik.errors.city
                  }
                </div>
              </div>
              <div className='flex-grow-1'>
                  <input type='text' className='form-control' placeholder='State' name='city' value={formik.values.state} onChange={formik.handleChange('state')} onBlur={formik.handleBlur('state')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.state && formik.errors.state
                  }
                </div>
              </div>
              <div className='flex-grow-1'>
                <input type='text' className='form-control' placeholder='ZIP code' name='pincode' value={formik.values.pincode} onChange={formik.handleChange('pincode')} onBlur={formik.handleBlur('pincode')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.pincode && formik.errors.pincode
                  }
                </div>
              </div>
              <div className='w-100'>
                <div className='d-flex justify-content-between align-items-center'>
                  <Link to='/cart' className='text-blue'><BiArrowBack className='me-2'/>Return to Cart</Link>
                   <button className='button' type='submit'>Option Pay</button>
                   <button className='button' type='submit'>Place Order</button>
                </div>
              </div>
            </form>
          </div>
          <div className='col-5'>
            <div className='border-bottom py-4'>
              {
                cartState && cartState?.map((item, index) => {
                  return (
                    <div key={index} className='d-flex gap-10 mb-2 align-items-center'>
                      <div className='w-75 d-flex gap-10'>
                        <div className='w-25 position-relative'>
                          <span className='badge bg-secondary text-white rounded-circle p-1 position-absolute'>{item?.quantity}</span>
                          <img className='img-fluid' src={item?.productId?.images[0]?.url} alt='product'/>
                        </div>
                        <div>
                          <h5 className='total-price'>{item?.productId?.title}</h5>
                          <p className='total-price'>{item?.color?.title}</p>
                        </div>
                      </div>
                      <div className='flex-grow-1'>
                        <h5 className='total'>{item?.price * item?.quantity}</h5>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className='border-bottom py-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='total'>Subtotal</p>
                <p className='total-price'>Bir {totalAmount?totalAmount:'0'}</p>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='mb-0 total'>Delivery</p>
                <p className='mb-0 total-price'>Bir 200 - 20000.00</p>
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center py-4'>
              <h4 className='total'>Total</h4>
              <div className='d-flex justify-items-center gap-10'>
                <p className='total'>Bir</p>
                <p className='total-price'>Bir {totalAmount?totalAmount +200:'0'}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Checkout
