import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { useDispatch} from 'react-redux';
import { createQuery } from '../features/contact/contactSlice';

const contactSchema = yup.object({
  name: yup.string().required('Name is Required'),
  email: yup.string().email('Email should be valid').required('Email Address is Required'),
  mobile: yup.number().required('Mobile Phone Number is Required'),
  comment: yup.string().required('Comment is Required'),
});

const Contact = () => {
  const dispatch = useDispatch()
  const formik = useFormik({
  initialValues: {
    name: '',
    mobile: '',
    email: '',
    comment: '',
  },
  validationSchema: contactSchema,
  onSubmit: (values) => {
    dispatch(createQuery(values));
  },
});
  return (
    <>
      <Meta title={'Contact Us'} />
      <BreadCrumb title='Contact Us' />
      <Container class1='contact-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d252226.0228033381!2d38.4671962!3d8.9692347!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b84f21691961f%3A0x822a438ed5adcc15!2sAddis%20Ababa!5e0!3m2!1sen!2set!4v1700660590537!5m2!1sen!2set" allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className='col-12 mt-5'>
            <div className='contact-inner-wrapper d-flex justify-content-between'>
              <div>
                <h3 className='contact-title mb-4'>Contact</h3>
                <form action='' onSubmit={formik.handleSubmit} className='d-flex flex-column gap-15'>
                  <div>
                    <input type='text' className='form-control' placeholder='Name' name='name' value={formik.values.name} onChange={formik.handleChange('name')} onBlur={formik.handleBlur('name')}/>
                    <div className='error ms-2 my-1'>
                  {
                    formik.touched.name && formik.errors.name
                  }
                </div>
                  </div>
                  <div>
                    <input type='email' className='form-control' placeholder='Email' name='email' value={formik.values.email} onChange={formik.handleChange('email')} onBlur={formik.handleBlur('email')}/>
                    <div className='error ms-2 my-1'>
                  {
                    formik.touched.email && formik.errors.email
                  }
                </div>
                  </div>
                  <div>
                    <input type='tel' className='form-control' placeholder='Mobile Number' name='mobile' value={formik.values.mobile} onChange={formik.handleChange('mobile')} onBlur={formik.handleBlur('mobile')}/>
                    <div className='error ms-2 my-1'>
                  {
                    formik.touched.mobile && formik.errors.mobile
                  }
                </div>
                  </div>
                  <div>
                    <textarea id='' className='w-100 form-control' cols='30' rows='4' placeholder='Comments' name='comment' value={formik.values.comment} onChange={formik.handleChange('comment')} onBlur={formik.handleBlur('comment')}></textarea>
                    <div className='error ms-2 my-1'>
                  {
                    formik.touched.comment && formik.errors.comment
                  }
                </div>
                  </div>
                  <div>
                    <button className='button border-0'>Send</button>
                  </div>
                </form>
              </div>
              <div>
                <h3 className='contact-title mb-4'>Get in touch with us</h3>
                <div>
                  <ul className='ps-0'>
                    <li className='mb-3 d-flex gap-15 align-items-center'>
                      <pre className='mb-0'>Nile Office: Tulu Demtu, Addis Abeba, Ethiopia</pre>
                    </li>
                    <li className='mb-3 d-flex gap-15 align-items-center'>
                      <a href='tel:+251 988309638'>+251 988309638</a>
                    </li>
                    <li className='mb-3 d-flex gap-15 align-items-center'>
                      <a href='mailto:nilecomet@gmail.com'>nilecomet@gmail.com</a>
                    </li>
                    <li className='mb-3 d-flex gap-15 align-items-center'>
                      <pre className='mb-0'>Monday - Friday 8 AM - 8 PM.</pre>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Contact
