import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';

const Sell = () => {
  return (
    <>
      <Meta title={'Sell on Nile'} />
      <BreadCrumb title='Market on Nile' />
      <Container class1='invest-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
            <h1>Nile Sellers</h1>
            <div className='d-flex gap-15 flex-column my-0'>
              <p>You are <b>Manufacturer, Supplier, Exporter, Importer</b> or need selling products online, <button class='button text-white' type='submit'><a href='https://www.sell.a-nile.com' target='_blank'>Click here</a></button>.</p>
            </div>
            <div className='d-flex gap-10 flex-column my-3'>
              <h3 className='about-us'>Seller Rules</h3>
              <p className='product-data'>Please, read our Terms and Policy.</p>
            </div>
          </div>
            </div>
        </div>
      </Container>
    </>
  )
}

export default Sell
