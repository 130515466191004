import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';

const Job = () => {
  return (
    <>
      <Meta title={'Nile Careers'} />
      <BreadCrumb title='Careers' />
      <Container class1='job-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
            <h1>Nile Careers</h1>
            <div className='d-flex gap-15 flex-column my-0'>
              <h3 className='about-us'>Workplace and Opportunity</h3>
              <p className='product-data'>Nile is a place for talent. Where you can put your talent out of the box and show it to the world. You are a software, website developer or have experience in other technologies like hardware, contact us. Our mission is to stablish a place where everyone can find anything they want to buy online and we strive to bring the innovative software and websites to consumers around the world.</p>
            </div>
            <div className='d-flex gap-10 flex-column my-3'>
              <h3 className='about-us'>Jobs Equality:</h3>
              <p className='product-data'>Nile is committed to a safe, friendly, calturally diverse and inclusive workplace. Nile is an equal opportunity employer and does not discriminate on the basis of race, national origin, religion, gender, disability, age, or other legally protected status.</p>
            </div>
          </div>
            </div>
        </div>
      </Container>
    </>
  )
}

export default Job
