import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';

const ReturnPolicy = () => {
  return (
    <>
      <Meta title={'Return Policy'} />
      <BreadCrumb title='Return Policy' />
      <Container class1='policy-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
            <h1>Return and Refund Policy</h1>
            <div className='d-flex gap-15 flex-column my-0'>
              <p className='product-data'>You may return most new, unopened items sold and fulfilled by Nile within 30 days of delivery for a full refund. To manage your return and refund process, print return labels and check the status of your recent returns. In the case the package is unlaminated, you can not return the item. Please, choose your item very carful before you buy it, if any question contact us.</p>
              <p className='product-data'>Usually in about 2-3 weeks. Most refunds are fully refunded within 7 days after we receive and process your return. Can't return an item? Sell your item for cash to other Nile customers (no listing fees for individual seller, set your own price, pay only when your item sells)</p>
            </div>
            <div className='d-flex gap-10 flex-column my-3'>
              <h3 className='about-us'>Replacements and Cxchanges</h3>
              <p className='product-data'>If you received a damaged or defective item, we’ll ship you a replacement of the exact item. If you would like to exchange an item for another, you can exchange for a different size or color or for an item in your Cart.</p>
            </div>
          </div>
            </div>
        </div>
      </Container>
    </>
  )
}

export default ReturnPolicy
