import React, {useEffect} from 'react';
import Container from '../components/Container';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import {useDispatch, useSelector} from 'react-redux';
import { getOrders } from '../features/user/userSlice';

const Orders = () => {
  const dispatch = useDispatch()
  const orderState = useSelector(state=>state?.auth?.getorderedProduct)
  useEffect(() => {
    dispatch(getOrders())
  },[])

  return (
    <>
      <Meta title={'Your Order'} />
      <BreadCrumb title='Order' />
      <Container class1='cart-wrapper home-wrapper-2 py-3'>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-3'>
                <h5>Order Id</h5>
              </div>
              <div className='col-3'>
                <h5>Total Amount</h5>
              </div>
              <div className='col-3'>
                <h5>Total Amount after Discount</h5>
              </div>
              <div className='col-3'>
                <h5>Status</h5>
              </div>
            </div>
          </div>       
          <div className='col-12 mt-2'>
            {orderState && orderState?.map((item, index) => {
                return (
                  <div className='row pt-3 my-3' style={{backgroundColor:'#ffffff'}} key={index}>
                    <div className='col-3'>
                      <p>{item?._id}</p>
                    </div>
                    <div className='col-3'>
                      <p>{item?.totalPrice}</p>
                    </div>
                    <div className='col-3'>
                      <p>{item?.totalPriceAfterDiscount}</p>
                    </div>
                    <div className='col-3'>
                      <p>{item?.orderStatus}</p>
                    </div>
                    <div className='col-12'>
                      <div className='row py-3' style={{backgroundColor:'#f5f5f5'}}>
                        <div className='col-3'>
                          <h6>Product Name</h6>
                        </div>
                        <div className='col-3'>
                          <h6>Quantity</h6>
                        </div>
                        <div className='col-3'>
                          <h6>Price</h6>
                        </div>
                        <div className='col-3'>
                          <h6>Color</h6>
                        </div>
                        {item?.orderItems?.map((i, index) => {
                            return (
                              <div className='col-12'>
                                <div className='row py-3' style={{backgroundColor:'#ffffff'}}>
                                  <div className='col-3'>
                                    <p>{i?.productId?.title}</p>
                                  </div>
                                  <div className='col-3'>
                                    <p>{i?.quantity}</p>
                                  </div>
                                  <div className='col-3'>
                                    <p>{i?.price}</p>
                                  </div>
                                  <div className='col-3'>
                                    <ul className='colors ps-0'>
                                      <li style={{backgroundColor:i?.color}}></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>  
                  </div>
                )
              })
            }
          </div>
        </div>
      </Container>
    </>
  )
}

export default Orders
