import wish from "../images/wish.svg";
import React from 'react';
import ReactStars from 'react-rating-stars-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToWishlist } from "../features/products/productSlice";

const ProductCard = (props) => {
  const { grid, data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const addToWish = (id) => {
    dispatch(addToWishlist(id));
  };
  return (
    <>
    {data?.map((item, index) => {
      return (
        <div key={index} className={` ${location.pathname === '/product' ? `gr-${grid}` : 'col-3'}`}>
          <div className='product-card mb-2 position-relative'>
            <div className='wishlist-icon position-absolute'>
              <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                <img src={wish} alt='wishlist'/>
              </button>
            </div>
            <div className='product-image'>
              <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
              <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
            </div>
            <div className='product-details'>
              <h6 className='brand'>{item?.brand}</h6>
              <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
              <ReactStars count={5} value={item?.totalrating.toString()} edit={false} size={24} activeColor='#ffd700' />
              <p className={`description ${grid === 12 ? 'd-block' : 'd-none'}`} dangerouslySetInnerHTML={{__html: item?.description}}></p>
              <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
            </div>
          </div>
        </div>
      )
    })}
    </>
  )
}

export default ProductCard
