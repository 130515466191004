import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';

const AdsPolicy = () => {
  return (
    <>
      <Meta title={'Advertisement Policy'} />
      <BreadCrumb title='Advertisement Policy' />
      <div className='policy-wrapper py-5 home-wrapper-2'>
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-12'>
              <div className='policy'>
              <h1>Advertisement Policy</h1>
              <div className='d-flex gap-15 flex-column my-0'>
                <h3 className='about-us'>Advertisement Preferences:</h3>
                <p className='product-data'>Nile offers you choices about receiving interest-based advertisements from us. In product advertising, we follow the Self-Regulatory Principles for Online Behavioral Advertising developed by the Digital Advertising Alliance (a coalition of marketing, online advertising, and consumer advocacy organizations). For that we use information such as your interactions with Nile sites, content, or services, but not your name or e-mail and other identifiers.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Advertisements:</h3>
                <p className='product-data'>Third-Party Advertisers and Links to Other Websites and apps: They are responsible for your interactions with them. Use of Third-Party Advertising Services: We provide them some informations (cookies) but not your or other information that directly identifies you.</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdsPolicy
