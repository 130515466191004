import React from 'react'
import Meta from '../components/Meta';
import { FaFacebookF, FaYoutube, FaInstagram, FaTelegram, FaTiktok, FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

const About = () => {
  return (
    <>
      <Meta title={'About Us'} />
      <div className='about-wrapper py-5 home-wrapper-2'>
        <div className='container-xxl'>
          <div className='row'>
            <div className='col-12'>
              <div className='policy'>
                <h1>About Nile and A-nile.com</h1>
                <div className='d-flex gap-15 flex-column my-0'>
                  <h3 className='about-us'>Who we are?:</h3>
                  <div>
                    <p><strong>Nile is a place for software and websites. All in one technology.</strong><br/><b>A-nile.com is a new coming e-commerce platform in Ethiopia.</b></p>
                    <span><b>A-nile.com</b> (owned by Nile) serves the connection between manufacturers, suppliers and consumers, importers and exporters.</span>
                    <p>It solves the customer shortage for companies and individuals. A place to sell and buy <b>everything</b> and advertise your <b>brands, products, businesses and everything else.</b></p>
                  </div>
                  <div>
                    <span className='product-data'><b>Nile:</b> We design static and dynamic websites for your business and suport you by usage, and we design softwares for your device and implement it. Further more we solve yor IT-System and digital problems and in database too <b>Meet us in our office, on email, telephone and even online</b></span>
                  <p>A-nile.com (kan Nile) walitti dhufeenya oomishtoota, dhiyeessitootaa fi fayyadamtoota, galchitootaa fi al-ergitoota gidduu jiru tajaajila. Hanqina maamiltootaa dhaabbilee fi namoota dhuunfaa ni fura. Bakka waan hunda gurguruu fi bitachuu fi maqaa, oomisha, daldalaa fi waan hunda itti beeksiftan. Nile: Daldala keessaniif marsariitiiwwan istaatiksii fi daayinamikii dizaayinii gochuun itti fayyadamaan isin deeggarra, meeshaa keessaniif ammoo sooftiweerii dizaayinii goona hojiirra oolchina. Further more we solve yor IT-System and digital problems and in database too Waajjira keenya keessatti, email, bilbilaa fi illee online irratti nu qunnamaa.</p>
                 </div>  
                </div>
                <div className='d-flex gap-10 flex-column my-3'>
                  <h3 className='about-us'>What we do:</h3>
                  <p className='product-data'>Delivery and Logistics, Store, Services <b>5-10 business days!</b></p>
                </div>
                <div className='d-flex gap-10 flex-column my-3'>
                  <h3 className='product-heading'>Our Office:</h3>
                  <p className='product-data'>Diversity, equity, inclusion. Saftey, sustainability, delivery partner, facilities, corporation, Nile News, Investors.</p>
                  <p>Heddumina, walqixxummaa, hammatamuu. Saftey, itti fufiinsa, michuu geejjibaa, dhaabbilee, korporeeshinii, Nile News, Investors.</p>
                </div>
                <div className='d-flex gap-10 flex-column my-3'>
                  <h3 className='about-us'>Follow us on Social Media:</h3>
                  <div>
                    <span className='product-data d-flex align-items-center my-3 gap-15'>
                      <a href='#' className='nav-a facebook fs-4'><FaFacebookF /></a>
                      <a href='https://youtube.com/nile.com_/' className='nav-a youtube fs-4'><FaYoutube /></a>
                      <a href='https://www.instagram.com/anile.com_/' className='nav-a instagram fs-4'><FaInstagram /></a>
                      <a href='#' className='nav-a telegram fs-4'><FaTelegram /></a>
                      <a href='#' className='nav-a tiktok fs-4'><FaTiktok /></a>
                      <a href='#' className='nav-a whatsup fs-4'><FaWhatsapp /></a>
                      <a href='#' className='nav-a tiktok fs-4'><MdOutlineEmail /></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
