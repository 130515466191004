import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';

const Invest = () => {
  return (
    <>
      <Meta title={'Invest in Nile'} />
      <BreadCrumb title='Invest in Nile' />
      <Container class1='invest-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
            <h1>Investment Plans</h1>
            <div className='d-flex gap-15 flex-column my-0'>
              <p>Do you have any essential plan for your money, effort, time, etc., Nile offers you to put your money, effort, time, etc. into Nile to make a profit or get advantage, or the money, effort, time, etc. used to do this. The goal is to generate income and appreciation over time. Because our investors are our owners, we can focus on the long-term rather than quarterly results.</p>
              <p className='product-data'>We welcome personal, institutional and financial professional investors and others to our high-quality investment plan. You are more than an investor, you are an owner. Annual reports, proxies and shareholder letters are availible for investors in our office.</p>
            </div>
            <div className='d-flex gap-10 flex-column my-3'>
              <h3 className='about-us'>Other Programms:</h3>
              <p className='product-data'>For more informations about officers and directors, corporate governance contact us and request documents. Events and Press are on our Nile News, visit this page.</p>
            </div>
          </div>
            </div>
        </div>
      </Container>
    </>
  )
}

export default Invest
