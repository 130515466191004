import React, { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts } from '../features/products/productSlice';
import { addToWishlist } from "../features/products/productSlice";
import ReactStars from 'react-rating-stars-component';
import { Link, useNavigate } from 'react-router-dom';
import wish from "../images/wish.svg";

const Home = () => {
  const productState = useSelector((state) => state.product.product);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getproducts();
  }, []);
  const getproducts = () => {
    dispatch(getAllProducts());
  };
  const addToWish = (id) => {
    dispatch(addToWishlist(id));
  };
  return (
    <>
      <div className='main' id='main-section'>
        <div class1='home-wrapper-1 main-slide py-0'>
          <div className='container-fluid p-0'>
          <div className='row'>
            <div className='col-12'>
              <div id="carouselExampleControls" className="carousel slide main-banner position-relative" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="images/main-banner-0.jpg" className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-item">
                    <img src="images/main-banner-1.jpg" className="d-block w-100" alt="..."/>
                  </div>
                  <div className="carousel-item">
                    <img src="images/main-banner-4.jpg" className="d-block w-100" alt="..."/>
                  </div>
                  <div className="carousel-item">
                    <img src="images/main-banner-2.jpg" className="d-block w-100" alt="..."/>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
                <div className='main-banner-content position-absolute'>
                  <h4>ALL TYRES FOR SUPERRIDE.</h4>
                  <h5>BEST PRICES.</h5>
                  <p>From 39500 Bir or 14000 Bir/Vati.</p>
                  <a className='button' href='tel:+251988309638'>BUY NOW</a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <Container class1='marquee-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='marquee-inner-wrapper card-wrapper'>
                <Marquee className='d-flex'>
                  <div className='mx-4 w-25'>
                    <img src='images/nile.png' alt='NILE'/>
                  </div>
                  <div className='mx-4 w-25'>
                    <img src='images/brand.png' alt='A-NILE.COM'/>
                  </div>
                  <div className='mx-4 w-25'>
                    <img src='images/brand.png' alt='NIS'/>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Beauty & Health</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'beauty') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Electronics</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'electronics') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Clothings & Shoes</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'clothings') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Culture</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'culture') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Personal Accessories & Articles</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'articles') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Home, Kitchen & Gardenings</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'home') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Educational Tools & Office Automations</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'educational') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Transport, Machinery & Metals</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'machinery') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Agriculture & Chemicals</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'chemicals') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Sports & Games</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'sports') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Arts & Architecture</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'arts') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Energy</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'energy') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='featured-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Software & Websites</h3>
            </div>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'software') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
        <Container class1='popular-wrapper home-wrapper-2 py-5'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Popular Products</h3>
            </div>
          </div>
          <div className='row'>
            {
              productState && productState?.map((item, index) => {
                if (item.tags === 'popular') {
                  return (
                    <div key={index} className={ 'col-3'}>
                      <div className='product-card mb-2 position-relative'>
                        <div className='wishlist-icon position-absolute'>
                          <button className='border-0 bg-transparent' onClick={(e) => {addToWish(item?._id)}}>
                            <img src={wish} alt='wishlist'/>
                          </button>
                        </div>
                        <div className='product-image'>
                          <img src={item?.images[0].url} className='img-fluid mx-auto' width={160} alt='product pic'/>
                          <img onClick={() => navigate('/product/'+item?._id)} src={item?.images[0].url} className='img-fluid' width={160} style={{cursor:'pointer'}} alt='product pic'/>
                        </div>
                        <div className='product-details align-items-center'>
                          <h6 className='brand'>{item?.brand}</h6>
                          <h5 onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='product-title'>{item?.title}</h5>
                          <p onClick={() => navigate('/product/'+item?._id)} style={{cursor:'pointer'}} className='price'><sup>Bir</sup>{item?.price}</p>
                        </div>
                      </div>
                    </div>
                  );
                }  
              })}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Home
