import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import Container from '../components/Container';

const PrivacyPolicy = () => {
  return (
    <>
      <Meta title={'Privacy Policy'} />
      <BreadCrumb title='privacy Policy' />
      <Container class1='policy-wrapper py-5 home-wrapper-2'>
        <div className='row'>
          <div className='col-12'>
            <div className='policy'>
              <h1>Privacy Policy</h1>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Thank you for trusting us</h3>
                <p className='product-data'>In this context we explain you that Nile and its affiliated partner collect and process your personal information through Nile websites, products, services, online and physical stores, and applications. We will handle your trust so carefully.</p>
              </div>
              <div className='d-flex gap-15 flex-column my-0'>
                <h3 className='about-us'>Your Informations:</h3>
                <p className='product-data'><b>Collecting your Informations:</b> We collect it to provide and improve our products and services. Here are some: Information You Give Us to use Nile services, Automatic Information (your interaction with products, content and services. We use cookies and other unique identifiers.) and information from Other Sources such us updated delivery and address informations to optimize and deliver your next purchase more easily. <b>Using and Sharing your Personal Informations:</b> We use it to operate, provide, develop, and improve the products and services that we offer our customers, such as purchase and delivery of products and services; provide, troubleshoot, and improve Nile services; recommendations and persolizations; comply with legal obligations; provide voice, image and camera services; communicate with you; advertising and fraud prevention and credit risks. Information about our customers is an important part of our business, and we are not in the business of selling our customers' personal information to others. We share for Transactions involving Third Parties; Third-Party Service Providers; Business Transfers; Protection of Nile and Others. Otherwise we ask you the permission.</p> 
                <p className='product-data'><b>Information Security:</b> Your security and privacy are very important to us. We use encryption protocols and software, payment card industry data security standard, physical, electronic, and procedural safeguards. It is important for you to protect against unauthorized access to your password and to your computers, devices, and applications. Use unique password and sign out, when you ended using., <b>Your Accessablity of your Informations:</b> You can access your name, address, payment methods, profile.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='about-us'>Cookies:</h3>
                <p className='product-data'>To enable our systems to recognize your browser or device and to provide and improve Nile Services, we use cookies and other identifiers</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='product-heading'>Children:</h3>
                <p className='product-data'>Nile does sell products for children but to adults. You are over 13 - 18 years we involve parent or guardian.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='product-heading'>Ethiopian Consumer Privacy:</h3>
                <p className='product-data'>Export Policy, Disputies, usable law. other policies</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='product-heading'>Other Countries Privacy:</h3>
                <p className='product-data'>Nile, Inc. participates in other countries Privacy Shield frameworks.</p>
              </div>
              <div className='d-flex gap-10 flex-column my-3'>
                <h3 className='product-heading'>Rules, Notices, and Revisions:</h3>
                <p className='product-data'>Any dispute over privacy falls under the law of the state of addis ababa. If any please contact us, we will keep protecting you.</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PrivacyPolicy
