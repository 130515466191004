import React, { useState } from 'react';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import Container from '../components/Container';
import { useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { updateProfile } from '../features/user/userSlice';
import {AiOutlineEdit} from 'react-icons/ai';

const profileSchema = yup.object({
  firstname: yup.string().required('First Name is Required'),
  lastname: yup.string().required('Last Name is Required'),
  email: yup.string().email('Email should be valid').required('Email Address is Required'),
  mobile: yup.string().required('Mobile Phone Number is Required'),
});

const Profile = () => {
  const getTokenFromLocalStorage = localStorage.getItem('customer')
  ? JSON.parse(localStorage.getItem('customer'))
  : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ''
      }`,
      Accept: 'application/json',
    },
  };
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth.user)
  const {edit,setEdit} = useState(true) //setEdit() is not a function here <AiOutlineEdit onClick={()=>setEdit(false)}/>
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      firstname: userState?.firstname,
      lastname: userState?.lastname,
      email: userState?.email,
      mobile: userState?.mobile,
    },
    validationSchema: profileSchema,
    onSubmit: (values) => {
      dispatch(updateProfile({data: values, config2: config2}));
      setEdit(true)
    },
  });

  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }
  return (
    <>
      <Meta title={'Your Account'} />
      <BreadCrumb title='Account' />
      <Container class1='cart-wrapper home-wrapper-2 py-3'>
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='my-3'>Manage Account</h3>
              <AiOutlineEdit onClick={()=>setEdit} style={{cursor:'pointer'}} className='fs-4'/>
            </div>
          </div>
          <div className='col-12'>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="fname" className="form-label">First Name</label>
                <input type="text" className="form-control" disabled={edit} id="fname" name='firstname' value={formik.values.firstname} onChange={formik.handleChange('firstname')} onBlur={formik.handleBlur('firstname')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.firstname && formik.errors.firstname
                  }
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="lname" className="form-label">Last Name</label>
                <input type="text" name='lastname' className="form-control" disabled={edit} id="lname" value={formik.values.lastname} onChange={formik.handleChange('lastname')} onBlur={formik.handleBlur('lastname')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.lastname && formik.errors.lastname
                  }
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email address</label>
                <input type="email" name='email' className="form-control" disabled={edit} id="email" aria-describedby="emailHelp" value={formik.values.email} onChange={formik.handleChange('email')} onBlur={formik.handleBlur('email')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.email && formik.errors.email
                  }
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="number" className="form-label">Mobile Phone Number</label>
                <input type="number" name='mobile' className="form-control" disabled={edit} id="mobile" aria-describedby="telHelp" value={formik.values.mobile} onChange={formik.handleChange('mobile')} onBlur={formik.handleBlur('mobile')}/>
                <div className='error ms-2 my-1'>
                  {
                    formik.touched.mobile && formik.errors.mobile
                  }
                </div>
              </div>
              <button type="submit" className="button btn-primary">Save</button>
              {
                edit===false && <button type="submit" className="button btn-primary">Save</button>
              }
              <button onClick={handleLogout} className='button text-white' type='submit'>Sign Out</button>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Profile
